<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}

.alert-primary {
    width: 100%;
}
</style>

<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="2">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">All</b-form-select-option>
                                    <b-form-select-option v-for="region in locations" :key="region.id" 
                                         :value="region.id">
                                        {{ region.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="2">
                        <b-form-group labe-for="location_id" label="Zone">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md"
                                    v-model="filter.location_id" @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="2">
                        <b-form-group labe-for="market_id" label="CPP">
                            <validation-provider #default="{ errors }" name="CPP" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="2">
                        <label>
                         From <small class="text-danger"></small> 
                        </label>
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="startDate" name="startDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                            </b-col>
                            <b-col cols="2">
                                <label>
                                    To <small class="text-danger"></small> 
                                    </label> 
                                    <validation-provider #default="{ errors }" name="End Date" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="md"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                            </b-col>

                        <b-col cols="2">
                        <div class="mt-10" style="margin-top: 24px">
                            <b-button
                            variant="outline-success"
                            size="md"
                            @click="generateReport()"
                        >
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                        </div>
                    </b-col>
                </b-row>

            </b-card-body>
        </b-card>
        <!-- ./Filters -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import salesReportsStoreModule from '@/views/cromis/reports/sales_report/salesReportsStoreModule'
import useSalesReportsList from '@/views/cromis/reports/sales_report/useSalesReportsList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import axios from '@axios';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModalPcn = ref(null)
        const myViewModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const totalMass = ref(null)
        const totalValue = ref(null)
        const startDate = ref(null)
        const endDate = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const totalWeight = ref(0)
        const tValue = ref(0)
        const companies = ref([])
        const societies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const children = ref([])
        const tikets = ref([])
        const saleId = ref()
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        const saleInfo = ref({})
        const filter = ref({
            region_id: null,
            region_name: null,
            locId: null,
            location: null,
            location_id: null,
            market_id: null,
            sale_id: null,
        })

        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)

        const CROMIS_STORE_MODULE_NAME = 'cromis-sale_documents'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, salesReportsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-sale_documents/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        // Form Methods
        const viewPcn = (item) => {
            tikets.value = item

            for (let index = 0; index < tikets.value.length; index++) {
                console.log(tikets.value[index])
                totalWeight.value += parseFloat(tikets.value[index].mass);
                tValue.value += parseFloat(tikets.value[index].value);
            }
            myModalPcn.value.show()
        }

        const approveRequisition = (item) => {
            console.log(item)
            handleApprove(item)
        }

        const getTotalValue = (valueArray) => {
            console.log(valueArray)
            totalValue.value = 0;
            for (let index = 0; index < valueArray.length; index++) {
                totalValue.value = valueArray[index].mass;
            }
            // valueArray.forEach(ele => {
            //     totalValue.value += ele.value;
            // });
            return totalValue.value;
        }

        const getTotalMass = (massArray) => {
            totalMass.value = 0;
            // console.log(massArray)
            // massArray.forEach(ele => {
            //     totalMass.value += ele.mass;
            // });

            for (let index = 0; index < massArray.length; index++) {
                totalMass.value = massArray[index].value;
            }
            return totalMass.value;
        }

        const getPcn = async () => {
            console.log(filter.value)
            filter.value.sale_id = filter.value.sale_id.id
            // saleId.value = filter.value.sale_id.id
            // let data = {
            //     "society_id": filter.value.society_id,
            //     "sale_id": filter.value.sale_id,
            //     "print": true
            // }

            let data = {
                "report_id": filter.value.doc_type,
                "sale_id": filter.value.sale_id,
                "society_id": filter.value.society_id
            }
            console.log(data)

            await store.dispatch('cromis-sale_documents/printDoc', data)
                .then(response => {

                    context.root.$swal({
                        icon: 'success',
                        text: 'Purchases Document prints initialized!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })

                    filter.value= {}
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "sale_documentsd": event
            }

            await store.dispatch('cromis-sale_documents/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to PCN has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const getAreaMarket = async () => {
            await store.dispatch('cromis-sale_documents/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            await store.dispatch('cromis-sale_documents/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    societies.value = response.data.sales.societies
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {
            // societyFilter.value = id
            let data = {
                "society_id": id,
                "sale_id": filter.value.sale_id.id,
                "print": false
            }
            console.log(data)


            await store.dispatch('cromis-sale_documents/create', data)
                .then(response => {
                    console.log(response)
                    saleInfo.value = response.data

                    console.log(saleInfo.value);
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const changeSale = async (event) => {
            societies.value = event.societies
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {
            locId.value = event
            console.log(locId.value)
            // filter.value.sale_id = event.id
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleCreate()
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }
        }

        const handleApprove = async (item) => {

            saving.value = true

            let data = {
                "approved": true
            }

            console.log(item)
            console.log(data)

            await store.dispatch('cromis-sale_documents/update', { id: item.id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Approved has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-sale_documents/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        const generateReport = async () => {
                let hasError = false
                serverErrors.value = {
                    location_id: null,
                    region_id: null,
                    region: null,
                    market_id: null,
                    startDate: null,
                    endDate: null,
                }
              
                if(!startDate.value){
                    hasError = true
                    serverErrors.value.startDate = ['Select To Date']
                }
                if(!endDate.value){
                    hasError = true
                    serverErrors.value.endDate = ['Select From Date']
                }
                if(!hasError){
                    saving.value = true
                    await window.open(`${ axios.defaults.baseURL }/reports/purchase_report?region_id=${region_id.value}&location_id=${location_id.value}&marketId=${ market_id.value }&startDate=${ startDate.value}&endDate=${ endDate.value}`, '_blank')
                    saving.value = false
                }
            }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,

            selectedOwner: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,

            startDate,
            endDate,
            generateReport,

            viewPcn,
            approveRequisition,
            submit,
            isFormValid,
            handleCreate,
            companies,
            societies,
            errosOcurs,
            locId,
            role,
            getAreaMarket,
            getAreaMarketSale,
            handleApprove,
            minDate,
            getPcn,
            handleCheckboxChange,
            getTotalMass,
            getTotalValue,
            totalMass,
            totalValue,
            tikets,
            totalWeight,
            tValue,
            saleInfo
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>